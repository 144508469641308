.bannersec .head {
  font-size: 75px;
  font-weight: 900;
  /* line-height: 140px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--themecolor);
}

.bannersec .head .headspan {
  background: var(--themecolor);
  color: var(--whitetext);
  padding-right: 7px;
}
.bannersec .head .headline {
  border-top: 1px solid var(--themelite);
  border-bottom: 1px solid var(--themelite);
}

.bannersec {
  min-height: 80vh;
  position: relative;
  overflow: hidden;
}

.bannersec .bannerrow {
  align-items: center;
  min-height: 80vh;
}
.bannersec .shadowleft {
  position: absolute;
  left: -15%;
  top: -40%;
}
.bannersec .shadowright {
  position: absolute;
  right: -15%;
  bottom: -20%;
}
.bannersec .btnsec {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%;
  margin-top: 20px;
}
.bannersec .bannerrow .leftsec {
  position: relative;
}
.bannersec .bannerrow .leftsec {
  position: relative;
}
.bannersec .bannerrow .leftsec .pattern {
  position: absolute;
  bottom: -10%;
  right: 10px;
  width: 80px;
  height: 80px;
}
.connectwalletbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  /* width: 100%; */
  min-width: 170px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 48px;
  position: relative;
}

.connectwalletbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.connectwalletbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.connectwalletbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.connectwalletbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.connectwalletbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.connectwalletbtn1 {
  background: transparent;
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--themecolor);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  /* width: 100%; */
  min-width: 170px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 48px;
  position: relative;
}

.connectwalletbtn1::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  top: 43%;
  transform: rotate(270deg);
}

.connectwalletbtn1::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  top: 43%;
  transform: rotate(90deg);
}

.connectwalletbtn1:hover {
  background: var(--themecolor);
  color: var(--whitetext);
}

.marqsec .marqtext {
  color: var(--themecolor);
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
  margin-left: 20px;
  margin-right: 20px;
}

.marqsec {
  display: flex;
  background: var(--themelite);
  height: 60px;
  align-items: center;
}

.featuresec .headrow {
  text-align: center;
}

.featuresec .headrow .catname {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--greytext1);
  margin-bottom: 5px;
}

.featuresec .headrow .head {
  font-size: 22px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  color: var(--themecolor);
}

.featuresec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.featuresec .featurecard {
  border: 1px solid #efe8ff;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100px;
}

.featuresec .featurecard .name {
  font-size: 16px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  margin-bottom: 0px;
}

.featuresec .icons {
  width: 60px !important;
  height: 60px;
  object-fit: contain;
}

.featuresec .featurecard .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  margin-bottom: 0px;
}

.featuresec .featurecard:hover {
  background: #efe8ff;
  cursor: pointer;
}

.featuresec .featurecard:hover .head {
  color: var(--themecolor);
}

.featuresec [class="owl-dots disabled"] {
  display: block !important;
}

.featuresec [class="owl-dots"] button span {
  background: var(--themecolor);
}

.featuresec [class="owl-nav"] {
  display: none;
}

.featuresec .sechead {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  color: var(--blacktext);
}

.regsec .head span {
  font-weight: 700;
}

.regsec .head {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
}

.regsec .desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
}

.regsec .btnsec {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%;
  margin-top: 20px;
}

.regsec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.joinsec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.joinsec .topbtnsec {
  align-items: center;
  justify-content: center;
}

.joinsec .topbtnsec button {
  width: auto;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}

.joinsec .head {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 20px;
}

.joinsec .head span {
  font-weight: 700;
}

.joinsec .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
}

.joinsec .joincard {
  background: url("../images/home/joincard.png");
  background-size: 100% 100%;
  min-height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  flex-direction: column;
}

.joinsec .joincard:hover {
  background: url("../images/home/joincardhover.png");
  cursor: pointer;
}

.joinsec .joincard .joinicon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.joinsec .joincard .head {
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--blacktext);
  margin-bottom: 5px;
}

.joinsec .joincard .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--blacktext);
  margin-bottom: 0px;
}

.joinsec .joincard:hover .head,
.joinsec .joincard:hover .desc {
  color: var(--whitetext);
}

.joinsec .joincard:hover .joinicon {
  filter: invert(1);
}

.pricecard {
  border: 1px solid var(--themecolor);
  border-radius: 10px;
  padding: 40px 25px;
  height: 100%;
  width: 320px;
  margin: auto;
}

.pricecardrow {
  justify-content: center;
}

.pricecard .name {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  margin-bottom: 10px;
}

.pricecard .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
}

.pricecard .price {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  margin-bottom: 0px;
}
.pricecard button {
  width: 100%;
}
.pricecard .priceicon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.pricecard:hover {
  background: var(--themelinear);
  cursor: pointer;
}

.pricecard:hover .priceicon {
  filter: invert(1);
}

.pricecard:hover .name,
.pricecard:hover .desc,
.pricecard:hover .price {
  color: var(--whitetext);
}

.pricecard:hover button {
  background: var(--whitetext) !important;
  color: var(--themecolor) !important;
}

.pricecard:hover button::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
  background: var(--themecolor);
}

.pricecard:hover button::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
  background: var(--themecolor);
}

.blocktechsec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.blocktechsec .techrow {
  background: linear-gradient(
    280.87deg,
    #eeecff 0.94%,
    rgba(221, 218, 248, 0.05) 100.19%
  );
  padding: 50px 20px;
  align-items: center;
  border-radius: 15px;
}

.blocktechsec .head {
  color: var(--themecolor);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.blocktechsec .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #373737;
}

.blocktechsec button {
  width: 150px;
}

.tradsec .tradrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tradsec {
  padding-top: 20px;
  padding-bottom: 40px;
}
.tradsec .tradrow {
  color: var(--themecolor);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.tradsec .tradrow .btnsec {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.tradsec .tradrow .btnsec button {
  min-width: 170px;
}
@media screen and (max-width: 767px) {
  .rowrev
  {
    flex-direction: column-reverse;
  }
  .bannersec .head {
    font-size: 40px;
  }
  .bannersec .btnsec {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .bannersec {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: unset;
  }
  .bannersec .bannerrow {
    min-height: unset;
  }
  .bannersec .leftsec {
    padding-bottom: 20px;
  }
  .regsec .btnsec {
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }
  .deficontentsec {
    margin-top: 20px;
  }
  .pricecard {
    width: 300px;
  }
  .tradsec .tradrow .btnsec {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .bannersec .shadowright {
    right: -40%;
  }
  .bannersec .pattern {
    width: 50px !important;
    height: 50px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {  
  .rowrev
  {
    flex-direction: column-reverse;
  }
  .bannersec .head {
    font-size: 60px;
  }
  .bannersec .btnsec {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .bannersec {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: unset;
  }
  .bannersec .bannerrow {
    min-height: unset;
  }
  .bannersec .leftsec {
    padding-bottom: 20px;
  }
  .regsec .btnsec {
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 30px;
  }
  .deficontentsec {
    margin-top: 20px;
  }
  .pricecard {
    width: 300px;
  }
  .tradsec .tradrow .btnsec {
    flex-wrap: wrap;
    width: 100%;
  }
  .bannersec .shadowright {
    right: -40%;
  }
  .bannersec .pattern {
    width: 50px !important;
    height: 50px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .bannersec .head {
    font-size: 60px;
  }
  .bannersec .btnsec {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .bannersec {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: unset;
  }
  .bannersec .bannerrow {
    min-height: unset;
  }
  .bannersec .leftsec {
    padding-bottom: 20px;
  }
  .regsec .btnsec {
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 30px;
  }
  .deficontentsec {
    margin-top: 20px;
  }
  .pricecard {
    width: 300px;
  }
  .tradsec .tradrow .btnsec {
    flex-wrap: wrap;
    width: 100%;
  }
  .bannersec .shadowright {
    right: -40%;
  }
  .bannersec .shadowleft {
    right: -60%;
  }
  .bannersec .pattern {
    width: 60px !important;
    height: 60px !important;
    bottom: 0px !important;
  }
  .joincard {
    height: 100%;
  }
}
