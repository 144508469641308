.innerpages {
  padding-top: 50px;
  padding-bottom: 70px;
  min-height: 70vh;
}
.predictionPage .topSection .pageTitle {
  color: var(--themecolor);
  text-transform: uppercase;
  font-weight: 700;
}
.predictionPage .topSection .valueButton {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  transition: none 0s ease 0s;
  padding: 5px 10px;
  min-width: 180px;
  border-radius: 5px;
  height: 46px;
  position: relative;
}

.predictionPage .topSection .valueButton:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  transition: none 0s ease 0s;
  padding-top: 5px;
  min-width: 180px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 46px;
  position: relative;
}
.predictionPage .topSection .valueButton::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 49%;
  transform: rotate(90deg);
}

.predictionPage .topSection .valueButton::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 49%;
  transform: rotate(270deg);
}

.predictionPage .topSection .valueButton:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.predictionPage .topSection .valueButton:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.predictionPage .topSection .valueButton:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.predictionPage .topSection .valueButton .logoBg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--violet_clr7);
  padding: 3px;
}
.predictionPage .topSection .valueButton .logoBg img {
  max-width: 19px;
}
.predictionPage .topSection .valueButton .amount {
  font-weight: 700;
}
.predictionPage .topSection .right .timerValue {
  color: var(--violet_clr8);
  font-size: 18px;
  font-weight: 600;
}
.predictionPage .topSection .right .timerValue span {
  font-size: 13px;
  font-weight: 400;
}
.predictionPage .topSection .right .clock {
  max-width: 42px;
}
.predictionPage .topSection .right .historyBg {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: var(--themelinear3);
  cursor: pointer;
}
.predictionPage .topSection .right .historyBg:hover {
  opacity: 0.9;
}
.predictionPage .topSection .right .historyBg img {
  max-width: 20px;
  margin-left: -2px;
}
