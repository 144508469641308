.lotteryInner .bannerSec {
  background: url("../images/lotterbanner.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 270px;
}

.lotteryInner .bannerSec .bannerTitle {
  color: var(--whitetext);
  font-weight: 600;
  line-height: 1.1;
  font-size: 16px;
}
.lotteryInner .bannerSec .number {
  color: var(--color_gold);
  font-size: 35px;
  font-weight: 700;
}
.lotteryInner .bannerSec .price {
  color: var(--whitetext);
  font-size: 26px;
  font-weight: 700;
}
.lotteryInner .ticketSec {
  padding-top: 50px;
  padding-bottom: 50px;
  background: var(--themelinear5);
}
.lotteryInner .ticketSec {
  padding-top: 50px;
  padding-bottom: 50px;
}
.lotteryInner .ticketSec .ticketInner .title {
  color: var(--blacktext);
  font-weight: 600;
}
.lotteryInner .timer {
  color: var(--blacktext);
  font-weight: 600;
  font-size: 14px;
}
.lotteryInner .timerValue {
  color: var(--themecolor);
  font-weight: 600;
  font-size: 23px;
}
.lotteryInner .timerText {
  font-size: 12px;
  color: var(--themecolor);
  font-weight: 600;
}

.lotteryInner .ticketSec .ticketCard {
  border-radius: 20px;
  margin-top: 30px;
  border: 1px solid var(--light_violet_clr4);
}
.lotteryInner .ticketSec .ticketCard .ticketCardTop {
  background: var(--light_linear);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 12px 20px;
}
.lotteryInner .ticketSec .ticketCard .cardTitle {
  color: var(--themecolor);
  font-weight: 600;
  font-size: 14px;
}
.lotteryInner .ticketSec .ticketCard .cardSubTitle {
  color: var(--themecolor);
  font-size: 13px;
  text-align: end;
}
.lotteryInner .ticketSec .ticketCard .content {
  padding: 20px;
  background: var(--whitetext);
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.lotteryInner .ticketCard .content .priceValue {
  color: var(--themecolor);
  font-weight: 600;
}
.lotteryInner .ticketCard .content .uoValue {
  color: var(--light_violet_clr3);
  font-size: 13px;
  margin-top: -3px;
}
.lotteryInner .ticketCard .content .buyTickets {
  color: var(--whitetext);
  background: var(--themelinear6);
  border: none;
  outline: none;
  padding: 10px 18px;
  font-weight: 600;
  border-radius: 12px;
  font-size: 12px;
}
.lotteryInner .ticketCard .content .buyTickets:hover {
  background: var(--themelinear2);
}
.finishedRounds
{
  background: linear-gradient(180deg, #F6F9FF 0%, #BFAEE2 100%) !important;
  border-bottom: 10px solid #7645D9;

}

.lotteryInner .ticketCard .bottom {
  border-top: 1px solid var(--light_violet_clr4);
  background: var(--whitetext);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.lotteryInner .ticketCard .bottom .detailsButton {
  border: none;
  outline: none;
  background: transparent;
  color: var(--green_clr);
  font-weight: 600;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.lotteryInner .ticketCard .bottom .showDetails {
  padding: 20px;
  background-color: var(--light_violet_clr4);
  font-size: 13px;
}

.lotteryInner .connectWallet {
  padding-top: 50px;
  padding-bottom: 50px;
  background: var(--themelinear7);
}
.lotteryInner .connectWallet .text {
  color: var(--whitetext);
  font-weight: 600;
}
.lotteryInner .connectWallet .connectButton {
  border: none;
  outline: none;
  background: var(--green_clr);
  color: var(--whitetext);
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 18px;
}
.lotteryInner .connectWallet .connectButton:hover {
  opacity: 0.7;
}
/* media */



@media (min-width: 576px) {
  .lotteryInner .bannerSec {
    height: 330px;
  }
  .lotteryInner .bannerSec .bannerTitle {
    font-size: 17px;
  }
  .lotteryInner .bannerSec .number {
    font-size: 48px;
  }
}

@media (min-width: 768px) {
  .lotteryInner .bannerSec {
    height: 350px;
  }
  .lotteryInner .bannerSec .bannerTitle {
    font-size: 18px;
  }
  .lotteryInner .bannerSec .number {
    font-size: 50px;
  }
  .lotteryInner .bannerSec .price {
    font-size: 30px;
  }
  .lotteryInner .timer {
    margin-top: 15px;
    font-size: 15px;
  }
  .lotteryInner .timerValue {
    font-size: 25px;
  }
  .lotteryInner .timerText {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .lotteryInner .bannerSec {
    height: 400px;
  }
  .lotteryInner .bannerSec .bannerTitle {
    font-size: 20px;
  }
  .lotteryInner .bannerSec .number {
    font-size: 55px;
  }
  .lotteryInner .bannerSec .price {
    font-size: 35px;
  }
  .lotteryInner .ticketSec {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .lotteryInner .connectWallet {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .lotteryInner .ticketSec {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .lotteryInner .ticketSec .ticketInner .title {
    font-size: 28px;
  }
  .lotteryInner .timer {
    margin-top: 15px;
    font-size: 17px;
  }
  .lotteryInner .timerValue {
    font-size: 33px;
  }
  .lotteryInner .timerText {
    font-size: 16px;
  }
  .lotteryInner .ticketSec .ticketCard .cardTitle {
    font-size: 15px;
  }
  .lotteryInner .ticketSec .ticketCard .cardSubTitle {
    font-size: 14px;
  }
  .lotteryInner .ticketCard .content .buyTickets {
    font-size: 14px;
  }
  .lotteryInner .ticketCard .bottom .showDetails {
    font-size: 14px;
  }
  .lotteryInner .ticketCard .bottom .detailsButton {
    font-size: 14px;
  }
  .lotteryInner .connectWallet {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .lotteryInner .connectWallet .text {
    font-size: 18px;
  }
  .lotteryInner .connectWallet .connectButton {
    font-size: 15px;
  }
}
@media (min-width: 1400px) {
  .lotteryInner .bannerSec {
    height: 450px;
  }
}

@media (min-width: 1920px) {
  .lotteryInner .bannerSec .bannerTitle {
    font-size: 25px;
  }
  .lotteryInner .bannerSec .number {
    font-size: 60px;
  }
  .lotteryInner .bannerSec .price {
    font-size: 45px;
  }
  .lotteryInner .ticketSec .ticketInner .title {
    font-size: 30px;
  }
}


.match_text_1
{
  color: var(--themecolor) !important;
  font-weight: 600;
  font-size: 16px;
}
.match_text_big
{
  color: var(--themecolor) !important;
  font-weight: 600;
  font-size: 25px;
}
.hrlottery
{
  border-color:#9773de;
  margin-bottom: 30px !important;
}
.match_text_2
{
  color: var(--bs-body-color) !important;

  font-weight: 600;
  font-size: 18px;
}
.match_text_lottery
{
  color: var(--bs-body-color) !important;

  font-weight: 600;
  font-size: 13px;
}
.match_text_1_pink
{
  color: var(--pink_clr) !important;
  font-weight: 600;
  font-size: 16px;
}


.tabstyletabs
{
border:none;
display: flex;
    align-items: center;
    justify-content: space-around;
    background: var(--light_violet_clr7);
    border-radius: 30px;
    padding: 0px;
    max-width: 327px;
    margin: 0 auto;
    border: 2px solid #7645df;
}

.tabstyletabs [class="nav-link"]
{
  cursor: pointer;
  font-size: 15px;
  color: var(--themecolor) !important;
  padding: 8px;
  border-radius: 30px;
  width: 100%;
  min-width: 160px !important;
  border:none !important;

}
.tabstyletabs [class="nav-item"]
{
  min-width: 160px !important;

}
.tabstyletabs [class="nav-link active"],
.tabstyletabs [class="nav-link"]:hover,
.tabstyletabs [class="nav-link"]:focus

{
  min-width: 160px !important;
  border:none !important;

  background: var(--violet_clr9) !important;
  border-radius: 30px !important;
  color: var(--themecolor) !important;

}
.badge_blue
{
  background-color:#eeeaf4;
    border-radius: 16px;
    box-shadow:  inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
    color: #280d5f;
    border: 1px solid #d7caec;
}
.arrowpagination
{
  cursor: pointer;
  margin-right: 20px;
}
.arrowpagination:last-child
{
  margin-right: 10px !important;
}

.arrowpagination:hover
{
  opacity: 0.7 !important;
}
.arrowpagination.disabled
{
  opacity: 0.5 !important;
  pointer-events: none;
  cursor: not-allowed !important;
}
.bartext
{
  position: relative;
}
.bartextleft
{
  position: relative;
}
.bartext::before
{
  content: "";
  position: absolute;
  border-right: 2px solid var(--themecolor);
  width: 2px;
    height: 13px;
    top: 4px;
}

.bartextleft::before
{
  content: "";
  position: absolute;
  border-right: 2px solid var(--themecolor);
  width: 2px;
    height: 13px;
    top: 4px;
    left: -2px;
}

.mixblend
{
  mix-blend-mode: multiply;
}
.mixblendsoft
{
  mix-blend-mode: soft-light;
}
.lotterydiv
{
  position: relative;
  max-width: 70px;
  margin-right:3px;
}
.lotterydiv svg
{
  max-width: 70px;
}
.lotterynumber
{
  top: 45%;
    left: 50%;
    position: absolute;
    color: rgb(0, 0, 0);
    text-shadow: white -0.75px -0.75px 0px, white 0.75px -0.75px 0px, white -0.75px 0.75px 0px, white 0.75px 0.75px 0px;
    /* transform: rotate(16deg); */
    font-weight: 600;
    line-height: 1.5;
    font-size: 42px;
    margin-bottom: 0px;
}
.lotteryfirst .lotterynumber
{
  transform: translate(-50%, -50%) rotate(330deg) !important;
}

.lotterysecond .lotterynumber
{
  transform: translate(-50%, -50%) rotate(30deg) !important;
}

.lotterythird .lotterynumber
{
  transform: translate(-50%, -50%) rotate(350deg) !important;
}

.lotteryfourth .lotterynumber
{
  transform: translate(-50%, -50%) rotate(10deg) !important;
}
.lotteryfifth .lotterynumber
{
  transform: translate(-50%, -50%) rotate(30deg) !important;
}
.lotterysixth .lotterynumber
{
  transform: translate(-50%, -50%) rotate(0deg) !important;
}


.lotteryfirst circle
{
  fill: rgb(215, 80, 178) !important;
}
.lotterysecond circle
{
  fill:rgb(168, 129, 252) !important;
}
.lotterythird circle
{
  fill: rgb(31, 199, 212);
}

.lotteryfourth circle
{
  fill: rgb(49, 208, 170);
}

.lotteryfifth circle
{
  fill: rgb(147, 212, 90);
}
.lotterysixth circle
{
  fill: rgb(255, 196, 60);
}
.ribbondiv
{
  z-index: 1;
    background-color: #7645d9;
    color: white;
    margin: 0px;
    padding: 8px 0px;
    position: absolute;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: left top;
    width: 96px;
    margin-bottom: 0px;
    position: absolute;
    right: -23px;
    top: -23px;
    font-size: 12px !important;
    text-align: center !important;
}
.withbottomradiuscontent
{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.titlepurple
{
  color:#7645D9;
}
.subtitleblk
{
  color:#000000;
  font-size: 15px;
}
.subtitleblksm
{
  color:#000000;
  font-size: 13px;
}
.cardplay
{
  border:1px solid #E7E3EB;
  box-shadow: 0px 2px 2px #E7E3EB;
  border-radius: 24px;
  padding: 20px;
}
.cardsteps
{
  color:#7645D9;
  font-size: 12px;
  text-transform: uppercase;
  text-align: right;

}
.cardtitlebuy
{
  color:#7645D9;
  font-size: 20px;
  font-weight: 600;

}
.cardtitlebuysm
{
  font-size: 16px !important;
}
.carddescbuy
{
color:#000000;
font-size: 16px;
}
.hrlottery
{
  border-color: #977faf;
  border-width: 2px;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.hrlotterysm
{
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
@media (max-width:575px)
{
.lotterynumber

{
  font-size: 20px !important;
}
  .tabstyletabs
  {
    max-width: 290px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
.tabstyletabs [class="nav-link"],
.tabstyletabs [class="nav-link active"],
.tabstyletabs [class="nav-link"]:hover,
.tabstyletabs [class="nav-link"]:focus

{
  min-width: 140px !important;
  font-size: 13px !important;
}
.tabstyletabs [class="nav-item"]

{
  min-width: 145px !important;

}
}


@media (min-width:576px) and (max-width:767px)
{
.lotterynumber
{
  font-size: 25px;
}
}
@media (min-width:768px)
{
  .cardplay
  {
    min-height: 210px;
  }
}
@media (min-width:768px) and (max-width:991px)
{
.lotterynumber
{
  font-size: 30px;
}
}
@media (max-width:767px)
{
  .flexrev
  {
    flex-direction: column-reverse;
  }
}

.viewtktbtn
{
  color: var(--green_clr);
    font-weight: 600;
    font-size: 13px;
    display: block;
    width: 100%;
    border:none;
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left !important;
}
.viewtktbtn:hover
{
  color:var(--themecolor);
}

.ticketviewsec
{
  flex-direction: column;
}