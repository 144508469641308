.innerpages {
  padding-top: 50px;
  padding-bottom: 70px;
  min-height: 70vh;
}
/* .innerpages [class="container"]{
    max-width: 1200px;
} */
.innerpages .headrow .pagehead {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--themecolor);
  margin-bottom: 5px;
}

.innerpages .headrow .pagedesc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--violet_clr3);
}
.innerpages .filterrow .leftfiltersec {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.innerpages .filterrow {
  align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle {
  display: flex;
  gap: 25px;
  align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle svg {
  cursor: pointer;
}
.innerpages .filterrow .leftfiltersec .liststyle svg:hover {
  fill: var(--themecolor);
}
.innerpages .filterrow .leftfiltersec .togglesec {
  display: flex;
  align-items: center;
  gap: 10px;
}

.innerpages .filterrow .leftfiltersec .togglesec .spanlabel {
  color: var(--blacktext);
}
.innerpages .filterrow .rightfiltersec {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
}
.innerpages .filterrow .leftfiltersec .tabsec {
  background: var(--violet_clr2);
  display: flex;
  border-radius: 35px;
}
.innerpages .filterrow .leftfiltersec .tabsec p {
  color: var(--blacktext);
  font-size: 15px;
  font-weight: 700;
  padding: 7px 20px;
  margin-bottom: 0px;
  cursor: pointer;
}
.innerpages .filterrow .leftfiltersec .tabsec p.active {
  background: var(--themecolor);
  color: var(--whitetext);
  border-radius: 35px;
}
.innerpages .filterrow .leftfiltersec .tabsec p:hover {
  color: var(--blacktext);
  opacity: 0.65;
}
.innerpages .dropdownstyle button {
  background: var(--violet_clr2);
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  color: var(--blacktext);
  font-size: 14px;
  height: 36px;
  padding-left: 15px;
  padding-right: 15px;
}

.innerpages .dropdownstyle button:active {
  background: var(--violet_clr2);
  color: var(--blacktext);
  border: none;
}

.innerpages .dropdownstyle .dropdownmenustyle {
  background: var(--violet_clr2);
  border: none;
  width: 100%;
  color: var(--blacktext);
}

.innerpages .dropdownstyle .dropdownmenustyle a {
  color: var(--blacktext);
}

.innerpages .dropdownstyle .dropdownmenustyle a:hover {
  color: var(--themecolor);
  background: none;
}
.innerpages .rightfiltersec .labelname {
  color: var(--themecolor);
  font-size: 14px;
}
.innerpages .rightfiltersec .inputstyle {
  background: var(--violet_clr2);
  box-shadow: none;
  border: none;
  color: var(--blacktext);
  border-radius: 20px;
  height: 36px;
  padding-left: 15px;
  padding-right: 15px;
}

.innerpages .rightfiltersec .inputstyle input {
  background: transparent;
  box-shadow: none;
  border: none;
  font-size: 14px;
  height: 100%;
  color: var(--blacktext);
}
.innerpages .rightfiltersec .inputstyle input::placeholder {
  color: var(--blacktext);
}
.innerpages .rightfiltersec .inputstyle input:focus {
  box-shadow: none;
}
.innerpages .rightfiltersec .inputstyle svg {
  cursor: pointer;
  font-size: 13px;
}
.innerpages .farmContentWrap {
  border: 1px solid var(--violet_clr);
  overflow: hidden;
  border-radius: 20px;
}
.innerpages .farmcontentsec .emptylabels {
  font-size: 15px;
  font-weight: 600;
  color: var(--blacktext);
  text-align: center;
}
.innerpages .poolscardsec {
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerpages .finshedlabel {
  color: var(--whitetext);
  text-align: center;
  margin-top: 30px;
}
@media screen and (max-width: 575px) {
  .innerpages .filterrow .leftfiltersec {
    justify-content: center;
  }
}

.emptylabels {
  font-size: 15px;
  font-weight: 600;
  color: var(--blacktext);
  text-align: center;
}
