.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  background: var(--cardbg);
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  min-width: 320px;
  max-width: 320px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}

.modalstyle .btnstyle {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 40px;
  position: relative;
}

.modalstyle .btnstyle:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}
.modalstyle .btnstyle::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.modalstyle .btnstyle::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}
.modalstyle .btnstyle:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.modalstyle .btnstyle:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}

.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  color: var(--themecolor);
  font-size: 20px;
}

.modalstyle .modalbody {
  padding: 20px 25px;
}

.modalstyle .modalbody .tabsec {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.modalstyle .modalbody .tabsec .tabbtn {
  background: var(--violet_clr);
  color: var(--whitetext);
  min-width: 110px;
}

.modalstyle .modalbody .tabsec .tabbtn.active {
  background: var(--themecolor);
}

.modalstyle .modalbody .tabsec .tabbtn:hover {
  background: var(--themecolor);
}
.modalbody .tabinnersec .copyinput input {
  background: var(--themecolor);
  border-radius: 15px;
  border: none;
  color: var(--whitetext);
  height: 50px;
}
.modalbody .tabinnersec .copyinput input:focus {
  box-shadow: none;
}
.modalbody .tabinnersec {
  padding-top: 20px;
}
.modalbody .tabinnersec .copyinput span {
  background: var(--themecolor);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border: none;
  height: 50px;
  cursor: pointer;
}
.modalbody .tabinnersec .label {
  color: var(--themecolor);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.modalbody .tabinnersec .balrow {
  display: flex;
  justify-content: space-between;
}
.modalbody .tabinnersec .balrow {
  color: var(--blacktext);
  font-size: 14px;
}
.modalbody .tabinnersec .balrow a {
  font-weight: 700;
  color: var(--themecolor);
  text-decoration: none;
}
.modalbody .tabinnersec .balrow a:hover {
  color: var(--dark_violet_clr);
}
.modalbody .tabinnersec .balrow .rightside {
  text-align: end;
}
.modalbody .tabinnersec .balrow .rightside p {
  margin-bottom: 4px;
}
.modalbody .tabinnersec .norecent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.modalbody .tabinnersec .recentlabel {
  color: var(--blacktext);
  font-size: 15px;
}
@media screen and (max-width: 400px) {
  .modalstyle .modalbody .innerheadwhite {
    font-size: 14px;
  }

  .modalstyle [class="modal-content"] {
    min-width: 280px;
    max-width: 280px;
  }
}
