.modalstyle .modalheader {
  border-bottom: none;
  background: var(--light_violet_Clr5);
  padding: 25px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  background: var(--cardbg);
  min-width: 280px;
  max-width: 280px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
}
.modalstyle .modaltitle {
  font-weight: 800;
  font-size: 18px;
  color: var(--blacktext);
}

.modalstyle .bodyTitle {
  color: var(--themecolor);
  font-weight: 700;
  font-size: 16px;
}
.modalstyle .desc {
  color: var(--light_violet_clr6);
  font-size: 14px;
}
.modalstyle .confirmText {
  color: var(--themecolor);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.6;
}
.modalstyle .continueButton {
  width: 100%;
  background: var(--themecolor);
  color: var(--whitetext);
  font-weight: 600;
  font-size: 14px;
  padding: 15px 15px;
  border-radius: 20px;
  border: none;
  outline: none;
}
.modalstyle .continueButton:hover {
  width: 100%;
  background: var(--themecolor);
  color: var(--whitetext);
  font-weight: 600;
  font-size: 14px;
  padding: 15px 15px;
  border-radius: 20px;
  border: none;
  outline: none;
  opacity: 0.7;
}

/* media */

@media (min-width: 350px) {
  .modalstyle [class="modal-content"] {
    background: var(--cardbg);
    min-width: 320px;
    max-width: 320px;
    margin: auto;
    border-radius: 30px;
    width: 100%;
  }
}
