.predictionCardPage {
  height: 409px;
}
.predictionCardPage.Expired {
  opacity: 0.7 !important;
}
.predictionCardPage .predictSliderCard {
  height: 100%;
  background: var(--themelinear4);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  border-radius: 20px;
}
.predictionCardPage .predictSliderCard.predictSliderCardGray {
  height: 100%;
  background: var(--light_violet_clr4);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  border-radius: 20px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.predictionCardPage .cardSecondaryBg {
  position: relative;
  height: 404px;
  top: 1px;
  width: 308px;
  background-color: var(--whitetext);
  border-radius: 20px;
}

.predictionCardPage .predictSliderCard .cardTop {
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--themecolor);
}

.predictionCardPage .predictSliderCard .cardTopNext {
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--whitetext);
  background: var(--themecolor);
}
.predictionCardPage .predictSliderCard .cardTopNext .cardCount {
  font-size: 14px;
}
.predictionCardPage .predictSliderCard .cardTop .cardCount {
  font-size: 14px;
}
.predictionCardPage .predictSliderCard .cardTopExpire {
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--light_black_clr);
  background: var(--light_violet_clr);
}
.predictionCardPage .predictSliderCard .cardTopLater {
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--dark_violet_clr2);
  background: var(--light_violet_clr);
}
.predictionCardPage .predictSliderCard .cardTopLater .cardCount {
  font-size: 14px;
  color: var(--light_violet_clr3);
}
.predictionCardPage .predictSliderCard .cardTop .liveLabel {
  font-weight: 600;
}

.predictionCardPage .predictSliderCard .progress {
  height: 8px;
  width: 100%;
  background-color: var(--light_violet_clr);
}
.predictionCardPage .predictSliderCard .nonProgress {
  height: 8px;
}
.predictionCardPage .predictSliderCard .progress .updatedProgress {
  height: 100%;
  background: var(--themecolor);
}
.predictionCardPage .predictSliderCard .cardBottom {
  margin-top: 20px;
  margin-bottom: 20px;
}
.predictionCardPage .predictSliderCard .cardBottom .enterLabel {
  background: var(--themecolor);
  top: -20px;
  left: 20px;
  font-size: 12px;
  color: var(--whitetext);
  font-weight: 600;
  padding: 3px 12px;
  border-radius: 16px;
}

.predictionCardPage .predictSliderCard .cardBottom .upperImage {
  background: url("../images/predictCardUpper.png");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 65px;
}
.predictionCardPage .predictSliderCard .cardBottom .upperImageNext {
  background: url("../images/predictcardupperwhite.png");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 65px;
}
.predictionCardPage .predictSliderCard .cardBottom .upperImageLater {
  background: url("../images/predictcardupperwhite.png");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 65px;
}
.predictionCardPage .predictSliderCard.confirmCard .cardBottom .upperImageNext {
  background: url("../images/confirmcardupper.png");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 65px;
}
.predictionCardPage .predictSliderCard.enterDown .cardBottom .upperImageNext {
  background: url("../images/pinkborderupper.png");
  background-repeat: no-repeat;
  background-position: bottom;
  height: 65px;
}
.borderContent {
  width: 85%;
  padding: 15px;
  border: 2px solid var(--themecolor);
  border-radius: 10px;
}
.confirmCard .borderContent {
  border: 1px solid var(--themecolor);
  min-height: 185px;
}
.enterDown .borderContent {
  border: 1px solid var(--pink_clr);
  min-height: 185px;
}

.borderContent.borderMinHeight {
  min-height: 190px;
  border: 2px solid var(--light_violet_clr4);
}
.predictionCardPage .predictSliderCard .cardBottom .bottomImage {
  background: url("../images/predictCardBottom.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 65px;
}
.predictionCardPage .predictSliderCard.confirmCard .cardBottom .bottomImage {
  background: url("../images/confirmcardbottom.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 65px;
  margin-top: -2px;
}
.predictionCardPage .predictSliderCard.enterDown .cardBottom .bottomImage {
  background: url("../images/pinkborderbottom.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 65px;
  margin-top: -2px;
}
.predictionCardPage .predictSliderCard .cardBottom .bottomImagelater {
  background: url("../images/predictCardBottom.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 65px;
  margin-top: -1px;
}
.predictionCardPage .predictSliderCard .cardBottom .rangeLabel {
  color: var(--whitetext);
  font-weight: 600;
  margin-top: 14px;
}
.predictionCardPage .predictSliderCard .cardBottom .upperImageNext .rangeLabel {
  color: var(--themecolor);
  font-weight: 600;
  margin-top: 14px;
}
.predictionCardPage
  .predictSliderCard
  .cardBottom
  .upperImageLater
  .rangeLabel {
  color: var(--blacktext);
  font-weight: 600;
  margin-top: 26px;
  opacity: 0.2;
}
.predictionCardPage .predictSliderCard .cardBottom .payoutLabel {
  font-size: 15px;
  color: var(--whitetext);
}
.predictionCardPage
  .predictSliderCard
  .cardBottom
  .upperImageNext
  .payoutLabel {
  color: var(--light_violet_clr3);
  font-size: 15px;
}
.predictionCardPage .predictSliderCard .cardBottom .payoutLabel span {
  font-weight: 600;
}
.predictionCardPage .predictSliderCard .borderContent .priceLabel {
  color: var(--light_violet_clr3);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.predictionCardPage .predictSliderCard .borderContent .priceAmount {
  color: var(--themecolor);
  text-decoration: underline;
  font-weight: 600;
  font-size: 20px;
}
.predictionCardPage .predictSliderCard .borderContent .valueButton {
  border: 1px solid var(--themecolor);
  background: var(--themecolor);
  color: var(--whitetext);
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
}
.predictionCardPage .predictSliderCard .borderContent .valueButton:hover {
  border: 1px solid var(--themecolor);
  background: transparent;
  color: var(--themecolor);
}
.predictionCardPage .predictSliderCard .borderContent .lockedPrice {
  color: var(--dark_violet_clr2);
  font-size: 16px;
}
.predictionCardPage .predictSliderCard .borderContent .prizePool {
  color: var(--dark_violet_clr2);
  font-weight: 600;
  font-size: 16px;
}
.predictionCardPage .predictSliderCard .borderContent .entryLabel {
  color: var(--dark_violet_clr2);
  font-size: 18px;
  font-weight: 600;
}
.predictionCardPage .predictSliderCard .borderContent .timeLabel {
  color: var(--dark_violet_clr2);
  font-size: 23px;
  font-weight: 700;
}

.predictionCardPage .predictSliderCard .borderContent .upButton {
  height: 45px;
  background: var(--themecolor);
  border: none;
  border-radius: 12px;
  color: var(--whitetext);
  font-weight: 600;
}
.predictionCardPage .predictSliderCard.enterDown .borderContent .upButton {
  background: var(--pink_clr);
}
.predictionCardPage .predictSliderCard.confirmCard .borderContent .upButton {
  width: 100%;
}
.predictionCardPage .predictSliderCard.enterDown .borderContent .upButton {
  width: 100%;
}
.predictionCardPage .predictSliderCard .borderContent .upButton:hover {
  opacity: 0.7;
}
.predictionCardPage .predictSliderCard .borderContent .downButton {
  height: 45px;
  background: var(--pink_clr);
  border: none;
  border-radius: 12px;
  color: var(--whitetext);
  font-weight: 600;
  margin-top: 6px;
}
.predictionCardPage .predictSliderCard .borderContent .downButton:hover {
  opacity: 0.7;
}

.predictionCardPage .predictSliderCard .bottomImage .payoutLabelBottom {
  color: var(--light_violet_clr3);
  margin-top: 5px;
  font-size: 14px;
}
.predictionCardPage .predictSliderCard .bottomImage .payoutLabelBottom span {
  font-weight: 600;
}
.predictionCardPage .predictSliderCard .bottomImage .rangeLabelBottom {
  color: var(--pink_clr);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}
.predictionCardPage .predictSliderCard .bottomImagelater .rangeLabelBottom {
  color: var(--blacktext);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.2;
  margin-top: 15px;
}

.flipCard {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flipCard .flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flipCardBack {
  transform: rotateY(180deg);
}


.rangeLabeluptext.rangeLabelBottom
{
  color: var(--themecolor) !important;
}

.rangeLabeldowntext.rangeLabel
{
  color: var(--pink_clr) !important;
}