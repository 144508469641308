.footersec {
  background: #e0ddf8;
}

.footersec .footerlogo {
  /* width: 150px; */
  width: 95px;
}

.footersec .footerrow {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footersec .sociallist {
  padding-left: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}

.footersec .sociallist img {
  aspect-ratio: 3 / 2;
  height: 33px;
  object-fit: contain;
  width: 33px;
}

.footersec .sociallist img.normal {
  padding: 6px;
}

.footersec .sociallist li .normal {
  display: block;
}

.footersec .sociallist li .hovericon {
  display: none;
}

.footersec .sociallist li:hover .normal {
  display: none;
}

.footersec .sociallist li:hover .hovericon {
  display: block;
}

.footersec .routelist {
  padding-left: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}

.footersec .rightsidesec {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.footersec .routelist li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000;
}

.footersec .routelist li a:hover {
  color: var(--themecolor);
}

.footersec .footerline {
  border-top: 1px solid var(--themecolor);
  margin: 0px;
}

.footersec .copysec {
  text-align: center;
}

.footersec .copysec p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--blacktext);
  margin-bottom: 0px;
  padding: 10px;
}

.footersec .copysec p span {
  color: var(--themecolor);
}
.footersec .centerline {
  border-right: 1px solid var(--themecolor);
  height: 40px;
}
@media screen and (max-width: 767px) {
  .footersec {
    padding: 25px 10px;
    text-align: center;
  }
  .footersec .footerrow {
    display: unset;
  }
  .footersec .sociallist {
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .footersec .rightsidesec {
    display: unset;
  }
  .footerrow .centerline {
    display: none;
  }
  .footersec .routelist {
    display: unset;
  }
  .footersec .footerline {
    margin: 10px;
  }
  .footersec .copysec {
    padding-bottom: 50px;
  }
}
